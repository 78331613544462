
<template>
    <header class="global-header container" :class="searchStore.globalHeaderClass" aria-label="Site header" role="banner">

        <div class="skip-navigation__wrapper">
            <a href="#main-content" class="skip-navigation">Skip to main content</a>
        </div>
        <div class="global-header__main-menu">
            <SiteLogo />
            <NavigationMenu />
        </div>
    </header>
</template>
<script lang="ts" setup>
import { useSearchStore } from '~/stores/search';

const searchStore = useSearchStore()
</script>
