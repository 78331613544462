<template>
    <div class="quick-search-bar js-quick-search-bar-mount" data-endpoint="/search" data-placeholder="Search" data-query="">
        <div class="quick-search-bar">
            <button @click="searchStore.toggle()" type="button" class="quick-search-bar__open"
                :aria-expanded="searchStore.isOpen" aria-label="Toggle Quick search" aria-controls="quick-search">
                <svg v-if="searchStore.isMobile" aria-hidden="true" width="14" height="14"
                    xmlns="http://www.w3.org/2000/svg">
                    <title>Search</title>
                    <g transform="matrix(-1 0 0 1 15 -1)" stroke="currentColor" strokeWidth="2" fill="none"
                        fillRule="evenodd">
                        <circle cx="9.333" cy="6.667" r="4.667"></circle>
                        <path d="M5.667 10.333l-4 4"></path>
                    </g>
                </svg>
                <template v-else>
                    <svg v-if="searchStore.isOpen" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="33"
                        height="34" viewBox="0 0 33 34">
                        <path fill="none" stroke="#fff" strokeLinecap="square" strokeMiterlimit="20" strokeWidth="2"
                            d="M2.154 3.154L31 32M1.88 31.118L30.998 2" />
                    </svg>
                    <svg v-else aria-hidden="true" width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                        <title>Search</title>
                        <g transform="matrix(-1 0 0 1 15 -1)" stroke="currentColor" strokeWidth="2" fill="none"
                            fillRule="evenodd">
                            <circle cx="9.333" cy="6.667" r="4.667"></circle>
                            <path d="M5.667 10.333l-4 4"></path>
                        </g>
                    </svg>
                </template>
            </button>
            <AnimateHeight class="modal--quick-search-bar modal-addi" :duration=300 :height=searchStore.height
                id="quick-search">
                <div class="modal__box-holder">
                    <div class="modal__overlay" @click="searchStore.toggle()" />
                    <div class="modal__box" role="dialog" aria-modal="true">
                        <div class="modal__content container">
                            <div class="quick-search-bar__modal">
                                <div class="quick-search-bar__search-form">
                                    <form class="search-form" @submit.prevent="onSubmit">
                                        <input type="text" class="search-form__input" placeholder="Search" v-model="query"
                                            aria-label="Search" />
                                        <button type="submit" class="search-form__submit btn" aria-label="Search">
                                            <svg class="search-form__icon" width="86" height="74" viewBox="0 0 69 73"
                                                xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                <title>Search</title>
                                                <defs>
                                                    <filter x="-5.9%" y="-51.2%" width="112.9%" height="194.9%"
                                                        filterUnits="objectBoundingBox" id="a">
                                                        <feOffset dx="8" dy="8" in="SourceAlpha"
                                                            result="shadowOffsetOuter1" />
                                                        <feGaussianBlur stdDeviation="15" in="shadowOffsetOuter1"
                                                            result="shadowBlurOuter1" />
                                                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                                            in="shadowBlurOuter1" result="shadowMatrixOuter1" />
                                                        <feMerge>
                                                            <feMergeNode in="shadowMatrixOuter1" />
                                                            <feMergeNode in="SourceGraphic" />
                                                        </feMerge>
                                                    </filter>
                                                </defs>
                                                <g filter="url(#a)" transform="translate(-1348 -43)" stroke="currentColor"
                                                    strokeWidth="2" fill="none" fillRule="evenodd">
                                                    <g transform="matrix(-1 0 0 1 1410 59)">
                                                        <circle cx="26.833" cy="19.167" r="13.417" />
                                                        <path d="M16.292 29.708l-11.5 11.5" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimateHeight>
        </div>
    </div>
</template>
<script setup lang="ts">
import AnimateHeight from 'vue-animate-height';
import { onMounted, onUnmounted } from 'vue';
import { useSearchStore } from '~/stores/search';

const searchStore = useSearchStore()
const query = ref('')

const handleResize = () => {
    searchStore.checkMobile();
};

onMounted(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});

async function onSubmit() {
    await navigateTo(`/search?q=${query.value}`)
    searchStore.toggle()
}

</script>
<style lang="scss" scoped>
@use "~/assets/scss/quick-search-bar.scss";
@use "~/assets/scss/modal--quick-search-bar.scss";
@use "~/assets/scss/search-form.scss";
</style>