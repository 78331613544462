<template>
    <nav class="primary-nav js-primary-nav" aria-label="primary navigation" role="navigation">
        <button class="primary-nav__toggle js-nav-toggle" aria-expanded="false">
            <span class="primary-nav__toggle-icon">
                <span></span>
            </span>
        </button>
        <div class="primary-nav__menu-wrap js-menu">
            <ul class="primary-nav__menu" role="group">
                <li v-for="(item, index) in navigationItems" :key="`lvl_${index}`" class="primary-nav__item js-nav-item"
                    :class="{ 'has-submenu js-has-submenu': item.subItems.length > 0 }">
                    <button v-if="item.subItems.length > 0" class="primary-nav__button js-submenu-toggle" type="button"
                        aria-expanded="false">
                        {{ item.title }}
                    </button>
                    <ul v-if="item.subItems.length > 0" class="primary-nav__submenu js-submenu" role="group"
                        :aria-labelledby="item.title" style="left: 0px;">
                        <li v-for="(subItem, index) in item.subItems" :key="`sublvl_${index}`"
                            class="primary-nav__subitem">
                            <NuxtLink :to="subItem.url" :target="subItem.url.startsWith('http') ? '_blank' : '_self'">
                                {{ subItem.title }}
                            </NuxtLink>
                        </li>
                    </ul>
                    <NuxtLink v-else :to="item.url" :target="item.url.startsWith('http') ? '_blank' : '_self'">
                        {{ item.title }}
                    </NuxtLink>
                </li>
                <li class="primary-nav__item js-nav-item">
                    <QuickSearchBar />
                </li>
            </ul>
        </div>
    </nav>
</template>
<script setup lang="ts">
import { initNavigation, closeMobileNav } from '~/public/js/navigation/PrimaryNav.js'
import type { NavigationItem } from '~/types/NavigationItem';

const navigationItems = ref<NavigationItem[]>([])

const siteStore = useSiteStore()

navigationItems.value = siteStore.getHeaderNavigationItems()

onMounted(() => {
    if (process.client) {
        initNavigation()
    }
})

const route = useRoute();
if (process.client) {
    watch(() => route.fullPath, () => {
        nextTick(() => {
            closeMobileNav()
        });
    });
}


</script>