<template>
    <RenderCacheable>
        <AppHeader />
    </RenderCacheable>
    <main id="main-content">
        <slot />
    </main>
    <RenderCacheable>
        <AppFooter />
    </RenderCacheable>
</template>
<script lang="ts" setup>
import { AtStaging } from '~/utils/at-staging';
import KontentSmartLink from "@kontent-ai/smart-link";
const kontentSmartLink = ref<KontentSmartLink>();

const siteConfig = useSiteStore();


const site = siteConfig.site;
if (site?.elements?.favicon?.value[0]?.url) {
    useHead({
        link: [{ rel: 'icon', type: 'image/x-icon', href: site.elements.favicon.value[0].url }],
    });
}

AtStaging((config) => {

    useHead(() => {
        return {
            bodyAttrs: {
                'data-kontent-project-id': config.public.kontentProjectId,
                'data-kontent-language-codename': "default",
            }
        }


    });

    onMounted(() => {

        if (kontentSmartLink.value) {
            kontentSmartLink.value.destroy();
        }
        kontentSmartLink.value = KontentSmartLink.initialize({
            queryParam: "enable-ksl-sdk"
        });
    })
});

</script>