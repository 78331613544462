interface SearchBarState {
  isOpen: boolean
  isMobile: boolean
  globalHeaderClass: string
  height: string | number
}

export const useSearchStore = defineStore('search', {
  state: (): SearchBarState => ({
    isOpen: false,
    isMobile: false,
    globalHeaderClass: '',
    height: 0
  }),
  actions: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.globalHeaderClass = this.isOpen ? 'global-header--quick-search-open' : '';
      this.height = this.isOpen ? 'auto' : 0;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 992;
    }
  }
})
